.rethink{
  margin-top: 140px;
}
.rethink-item{
  height: 380px;
  padding: 10px;
  margin: 46px 0 0 0;
  flex-shrink: 0;

  width: 260px;

  &__img{
    display: block;
    margin: auto;
  }

  &__cap{
    object-fit: contain;
    font-family: Avenir-Heavy,sans-serif;
    font-size: 28px;
    font-weight: 900;
    color: #273147;

    margin: 20px auto 0 auto;
    text-align: center;
  }
  &__text{
    font-weight: 500;
    color: #273147;
    margin: 28px auto 0 auto;
    text-align: center;
  }
}


.smarter{
  margin-top: 140px;
  .desc-block{
    margin-top: 10px;
  }
}


.search-display{
  margin-top: 140px;
}


.recommend{
  margin-top: 140px;
  .illustration{
    display: flex;
    align-items: center;
  }
}


.platform{
  margin-top: 140px;
}


.payment{
  margin-top: 140px;
  .illustration{
    display: flex;
    align-items: center;
  }
}


.recent{
  margin-top: 140px;
  .wrapper{
    flex-wrap: wrap;
  }
  .item-tags{
    margin-top: 24px;
  }
}
.recent-item{
  width: 350px;
  height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 47px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  display: flex;
  padding: 15px;
  margin: 90px 0 0 0;
  flex-wrap: wrap;
  align-items: flex-start;
  &__desc{
    width: 170px;
  }
  &__cap{
    font-family: Avenir-Heavy, sans-serif;
    font-size: 24px;
    font-weight: 900;
    a, a:hover{
      color: #273147
    }
  }
  &__text{
    font-size: 14px;
    font-weight: 500;
    line-height: 2.06;
    margin-top: 10px;
  }
  &__img{
    width: 134px;
    height: auto;
    margin: 0 0 0 auto;
  }
}


.trending{
  margin-top: 140px;
  padding-bottom: 158px;
  .wrapper{
    flex-wrap: wrap;
  }
}
.trending-item{
  margin: 90px 0 0 0;
  width: 260px;
  height: 360px;
  border-radius: 4px;
  box-shadow: 0 2px 47px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding: 15px;
  &__img{
    width: 100%;
    height: auto;
  }
  &__cap{
    font-family: Avenir-Heavy, sans-serif;
    font-size: 24px;
    font-weight: 900;
    margin-top: 17px;
    a, a:hover{
      color: #273147
    }
  }
  &__text{
    font-size: 14px;
    font-weight: 500;
    line-height: 2.06;
    margin: 8px auto 7px auto;
  }
  .activity{
    margin-top: 30px;
    &__views{
      padding-left: 45px;
    }
    &__likes{
      padding-left: 43px;
      &:before{
        left: 15px;
      }
    }
    &__date{
      display: inline-block;
      margin: 0 0 0 auto;
    }
  }
}


@media all and (max-width: 1440px){
  .rethink-item{
    &__cap{
      font-size: 25px;
    }
  }
}

@media all and (max-width: 1280px){
  .rethink{
    margin-top: 80px;
    .wrapper{
      flex-wrap: wrap;
    }
  }
  .rethink-item{
    height: 370px;

    width: 25%;
    &__cap{
      font-size: 25px;

      margin: 30px auto 0 auto;
    }
    &__text{
      margin: 15px auto 0 auto;
    }

    &__img {
      max-width: 100%;
    }

  }



  .search-display,
  .smarter{
    margin-top: 80px;
  }

  .recommend{
    margin-top: 80px;
    .desc-block{
      margin: 0 10px 0 0;
      &__desc-text{
        margin-bottom: 15px;
      }
    }
  }

  .payment{
    margin-top: 80px;
    .desc-block{
      margin: 0 10px 0 0;
      &__desc-text{
        margin-bottom: 0;
      }
      &__buttons{
        margin-top: 15px;
      }
    }
    .illustration{
      margin: 0 0 35px 0;
      width: 50%;
    }
  }


  .recent{
    .wrapper{
      max-width: 720px;
    }
  }
  .recent-item{
    width: 100%;
    height: 290px;
    padding: 25px 35px;
    margin: 60px 0 0 0;
    &__desc{
      width: auto;
      max-width: 290px;

    }
    &__cap{
      width: auto;
      font-size: 24px;
    }
    &__text{
      width: auto;
      font-size: 16px;
      br{
        display: none;
      }
    }
    &__img{
      width: 175px;
      height: auto;
      margin: 0 0 0 auto;
    }
  }

  .trending{
    .wrapper{
      justify-content: space-between;
      max-width: 720px;
    }
    padding-bottom: 120px;
  }
  .trending-item{
    margin: 90px 0 0 0;
    width: 294px;
    height: 420px;

    padding: 15px 15px 0 15px;

    &__img{
      width: 100%;
    }
    &__cap{
      font-size: 24px;
      margin-top: 17px;
    }
    &__text{
      font-size: 16px;
      margin: 8px auto 7px auto;
    }
    .activity{
      margin-top: 47px;
      &__views{
        padding-left: 45px;
      }
      &__likes{
        padding-left: 43px;
        &:before{
          left: 15px;
        }
      }
      &__date{
        margin: 0 31px 0 auto;
      }
    }
  }
}

@media all and (max-width: 768px){
  .smarter, .search-display, .recommend,
  .platform, .payment{
    .illustration{
      width: auto;
      margin-bottom: 30px;
      padding: 0;
    }
    .desc-block{
      padding: 0;
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }

  .wrapper{
    align-items: stretch;
    &--toleft {
      flex-direction: column;
    }
    &--toright {
      flex-direction: column-reverse;
    }
  }

  .rethink{
    .wrapper{
     justify-content: center;
    }
  }
  .rethink-item {
    text-align: center;
    height: auto;
    margin-top: 0;
    width: 50%;
    &__cap,
    &__text{
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  .recommend{
    .illustration{
      margin: 0 auto 30px;
    }


  }

  .wrapper {
    padding: 0 15px;
  }

  .smarter .desc-block {
    margin: 0;

  }

  .desc-block {
    width: 100%;
  }

  .search-display,
  .recommend,
  .platform,
  .payment,
  .recent,
  .trending,
  .smarter{
    margin-top: 40px;
  }

  .trending {
    padding-bottom: 40px;
  }

  .trending-item {
    margin-top: 20px;
  }

  .recent-item {
    margin-top: 20px;
  }

  .section-header{
    &--centred{
      padding: 0 15px;
    }
  }

  .section-sup-header {
    width: auto;
    padding: 0 15px;
  }

  .payment .illustration {
    width: auto;
    margin-bottom: 20px;
  }
}

@media all and (max-width: 630px){
  .recent-item {
    padding: 10px;
    height: auto;
    &__cap{
      margin-top: 20px;
    }
    &__img {
      order: -1;
      margin: auto;
    }

    &__desc {
      width: 100%;
      max-width: 100%;
    }
  }

  .trending .wrapper {
    justify-content: center;
  }
}