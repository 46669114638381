.deck{
  padding-top: 300px;

  .desc-block{
    &__desc-cap{
      margin: 22px 0px 47px 5px;
    }
    &__desc-text{
      margin: 0 0 37px 5px;
    }
  }

}

.wedding{
  margin-top: 60px;

  .desc-block{
    &__header{
      margin-bottom: 40px;
    }
    &__desc-cap{
      margin: 22px 0px 47px 5px;
    }
    &__desc-text{
      margin: 0 0 37px 5px;
    }
  }
}

.cake{
  margin-top: 60px;
  .desc-block{
    &__header{
      margin-bottom: 38px;
    }
    &__desc-text{
      margin: 0 0 37px 0;
    }
  }

}

.website{
  margin-top: 60px;
  .desc-block{
    &__header{
      margin-bottom: 40px;
    }
    &__desc-text{
      margin-bottom: 37px;
    }
  }
}

.weaving{
  margin-top: 60px;
  .ledge{
    height: auto;
    margin-top: 0;
  }
  .ledge__content{
    height: auto;
    padding: 82px 0;
  }
  &__text{
    font-size: 22px;
    margin: auto auto 26px auto;

    &--middle{
      margin-bottom: 62px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.values{
  margin-top: 60px;
  padding-bottom: 165px;

  .section-header--centred {
    padding: 0 40px 0 0;
  }

  .wrapper{
    padding-top: 63px;
    justify-content: space-between;
  }
}

.values-item{
  padding: 5px;
  margin: 35px 0 auto;
  max-width: 260px;
  &__desc{
    margin-top: 63px;
  }
  &__cap{
    font-family: Avenir-Heavy, sans-serif;
    font-size: 28px;
    font-weight: 900;
    color: #273147;
  }
  &__text{
    line-height: 2.06;
    color: #273147;
    margin-top: 20px;
  }

  //&--1{
  //  width: 300px;
  //}
  //&--2{
  //  width: 333px;
  //}
  //&--3{
  //  width: 293px;
  //}
  //&--4{
  //  width: 282px;
  //}
}

@media all and (max-width: 1440px){
  .weaving{
    &__text{
      font-size: 20px;
    }
  }
  .values-item{
    &__cap{
      font-size: 25px;
    }
  }
}

@media all and (max-width: 1280px){
  .deck, .wedding, .cake,
  .website, .weaving{
    margin-top: 30px;
  }

  .values{
    margin-top: 60px;
    padding-bottom: 165px;

    .wrapper{
      flex-wrap: wrap;
      padding-top: 0;
    }
    .values-item {
      max-width: 25%;
    }
  }
  .weaving{
    .ledge__content{
      font-size: 18px;
    }
  }

  .values-item img {
    max-width: 100%;
  }
}

@media all and (max-width: 768px){
  .deck, .wedding, .cake,
  .website, .weaving{
    margin-top: 0;
    .desc-block{
      margin: 0;
      max-width: 100%;
      order: 2;
      &--toright{
        margin: 0;
      }
    }
    .illustration{
      margin: 0;
      width: auto;
    }
  }

  .deck{
    padding-top: 80px;

  }
  .website{
    .desc-block{
      margin-top: 60px;
    }
  }

  .values{
    padding-bottom: 90px;

  }

  .values {
    .values-item{
      max-width: 50%;
    }
  }
  .values-item{
    padding: 5px;
    position: relative;

    img{
      display: block;
      position: relative;
    }
    &__desc{
      margin-top: 63px;
    }
    &__cap{
      text-align: center;
    }
    &__text{
     text-align: center;
    }

   /* &--1{
      width: 300px;
      img{
        top: 0;
        left: 60px;
      }
    }
    &--2{
      width: 300px;
      img{
        top: 0;
        left: 20px;
      }
    }
    &--3{
      width: 300px;
      img{
        top: 0;
        left: 15px;
      }
    }
    &--4{
      width: 300px;
      img{
        top: 0;
        left: 37px;
      }
    }*/
  }

  .weaving .ledge__content {
    padding: 30px 15px;
  }

  .values .section-header--centred {
    padding: 0 15px;
  }
}

@media all and (max-width: 425px){

}