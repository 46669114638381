.header{
  background: url("../img/header-bg-2.png") no-repeat;
  background-size: auto;
  height: 338px;
  &--home{
    height: 937px;
    background: url("../img/group-16.svg") no-repeat;
    //padding-bottom: 36px;
  }
}

.head-nav{
  max-width: 1140px;
  padding: 0 20px;
  display: flex;
  margin: auto;
  height: 89px;
  align-items: center;
  .collapsing{
    transition:height .35s ease;
  }
  .logo{
    width: 180px;
    &__img{
      width: 100%;
      height: auto;
      &--m{
        display: none;
      }
    }
  }
  &__links{
    margin: auto 40px auto auto;

    display: flex;
    justify-content: space-between;

    width: 440px;
  }
  &__link{
    font-size: 19px;
    font-weight: 500;
    span{
      position: relative;
    }
    span:before{
      content: '';
      display: block;
      left: calc(50% - 19px);
      bottom: -22px;
      position: absolute;
      transition: all .5s ease-in-out;
    }
    &--current span:before{
      height: 3px;
      background-color: #fff;
      width: 38px;
    }
    &:hover span:before{
      height: 3px;
      background-color: #fff;
      width: 38px;
    }
  }

  &__sign-up{
    margin: auto 0 auto 0;
    font-size: 19px;
    font-weight: 500;
  }
  &__btn{
    width: 120px;
    height: 40px;
    border-radius: 26px;
    margin: auto;
    &--log{
      border: 1px #fff solid;
      &:hover{
        box-shadow: inset 0 0 40px 40px rgba(255, 255, 255, .2);
      }
    }
    &--sign{
      width: auto;
      margin: auto 0 auto 20px;
    }
  }
}
.navbar-toggler{
  display: none;
  margin: 0 0 0 0;
  position: relative;
  width: 51px;
  height: 35px;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}
.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}
.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform .35s ease-in-out;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(135deg);
  opacity: 0.9;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(-135deg);
  opacity: 0.9;
}

.header-text{
  color: #fff;
  margin: 47px 20px 0 0;
  flex-shrink: 0;
  &__header{
    font-family: Avenir-Black, sans-serif;
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &__text{
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 27px;
  }
}

.header-buttons{
  margin-top: 69px;
}

.header-img{
  margin: 0 0 0 0;
  overflow: hidden;
  img{
    width: 100%;
    height: auto;
  }
}

.path{
  max-width: 1140px;
  padding: 0 15px;
  margin: 94px auto 0 auto;

  &__current{
    margin-left: 12px;
  }
}

.ledge{
  padding: 0 15px;
  margin: 47px auto 0;
  width: 100%;
  max-width: 1140px;
  &__content{
    height: 320px;
    border-radius: 4px;
    box-shadow: 0 2px 47px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 82px 0 0 0;
  }
  .section-header--centred{
    padding-left: 0;
  }
  .section-sup-header{
    max-width: 960px;
  }
}


@media all and (min-width: 1921px){
  .header{
    background-size: cover;
  }
  //.head-nav{
  //  max-width: 1920px;
  //}
  //.path{
  //  max-width: 1920px;
  //}
  //.ledge{
  //  max-width: 1920px;
  //}
}

@media all and (max-width: 1680px){
  .ledge{
    padding: 0 15px 0;
    width: auto;
    &__content{
      width: auto;
      height: 280px;
      padding: 52px 0 0 0;
    }
    .section-header--centred{
      padding-left: 0;
    }
  }
}

@media all and (max-width: 1440px){

}


@media all and (max-width: 1280px){
  .header{
    &--home{
      height: 600px;
    }
  }
  .head-nav{
    height: 60px;
    .logo{
      margin: 0;
      width: 140px;
      img{
        width: 100%;
        height: auto;
      }
    }
    &__links{
      margin: auto 12px auto auto;
      width: 370px;
    }
    &__link{
      font-size: 16px;
      span:before{
        left: calc(50% - 18px);
        bottom: -12px;
      }
      &--current span:before{
        height: 2px;
        width: 36px;
      }
      &:hover span:before{
        height: 2px;
        width: 36px;
      }
    }

    &__sign-up{
      margin: auto 0 auto 0;
      font-size: 16px;
    }
    &__btn{
      width: 90px;
      height: 30px;
      margin: auto 6px;
      &--sign{
        width: 68px;
      }
    }
  }

  .header-text{
    margin: 0;
    flex-shrink: 0;
    width: calc(50% - 40px);
    &__header{
      font-size: 32px;
    }
    &__text{
      font-size: 18px;
      margin-top: 17px;
    }
  }
  .header-buttons{
    margin-top: 17px;
    &__btn{
      height: 38px;
      border-radius: 4px;
      font-size: 16px;
      &--large{
        width: 220px;
        margin-right: 10px;
      }
    }
  }

  .path{
    margin: 123px auto 0 auto;
  }
}


@media all and (max-width: 1024px){
  .header-text{
    br{
      display: none;
    }
  }
}

@media all and (max-width: 768px){
  .header{
    height: auto;
    padding-bottom: 90px;
    background-size: cover;
    &--home{
     background-size: 400%;
    }
    .ledge{
      margin: 47px auto -170px auto;
    }
  }

  .head-nav{
    flex-wrap: wrap;
    height: auto;
    padding-top: 15px;
    .logo{
      width: 120px;
    }
    &__links{
      width: 100%;
      order: 3;
      flex-direction: column;
      margin: 20px auto 0 auto;
    }
    &__link{
      margin-bottom: 15px;
      text-align: center;
      span:before{
        left: 0;
        bottom: -5px;
        width: 100%;
      }
      &--current span:before{
        height: 2px;
      }
      &:hover span:before{
        height: 2px;
        width: 100%;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__sign-up{
      margin: 0 0 0 auto;
    }
  }
  .navbar-toggler{
    display: block;
  }

  .header-text{
    margin: 0 auto;
    width: auto;
    max-width: 400px;
    &__header{
      text-align: center;
    }
    &__text{
      font-size: 18px;
      margin-top: 27px;
      text-align: center;
    }
  }
  .header-img{
    width: auto;
    margin: 40px 0 0 -15px;
    max-width: 100%;
    flex-shrink: 0;

    overflow: visible;
    img{
      width: calc(100% + 30px);
      display: block;
    }
  }
  .header-buttons{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .ledge{
    padding: 0;
    &__content{
      height: auto;
      padding: 30px 0;
    }
    .section-sup-header {
      margin: 20px auto;
    }
  }
}
@media all and (max-width: 630px){
  .header{
    &--home{
      background-size: 500%;
    }
  }
  .header-buttons {
    flex-direction: column;
    align-items: center;
    &__btn--large{
      margin: 0 0 10px 0;
    }
  }
}
@media all and (max-width: 425px){
  .header{
    &--home{
      background-size: 730%;
    }
  }
  .head-nav{
    padding: 10px 10px 0 10px;
    .logo{
      width: 28px;
     &__img{
       display: none;
       &--m{
         display: block;

       }
     }
    }
    &__links{
      width: 100%;
      order: 3;
      flex-direction: column;
      margin: 20px auto 0 auto;
    }
    &__link{
      margin-bottom: 15px;
      text-align: center;
      span:before{
        left: 0;
        bottom: -5px;
        width: 100%;
      }
      &--current span:before{
        height: 2px;
      }
      &:hover span:before{
        height: 2px;
        width: 100%;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__sign-up{
      margin: 0 5px 0 auto;
      font-size: 14px;
    }
    &__btn{

    }
    .navbar-toggler{
      padding: 2px 0;
      font-size: 16px;
      width: auto;
      height: 30px;;
    }
    .navbar-toggler:not(.collapsed) span:nth-child(1) {
       left: 0px;
       top: 10px;
     }
    .navbar-toggler:not(.collapsed) span:nth-child(3) {
      left: 0px;
      top: 10px;
    }
  }
}