.tabs{
  margin-top: -78px;
  #accordion{
    margin: auto;
  }
  .nav-tabs{
    border: none;
    max-width: 800px;
    margin: 0 auto;

    .nav-link {
      border: none;
      width: 266px;
      height: 87px;
      border-radius: 5px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      &.active{
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
        color: #273147;
      }

      &:hover{

      }
    }
  }

  .tab-content{
    padding-top: 158px;
  }
}

@media all and (max-width: 1440px){
  .tabs{
    .nav-tabs .nav-link {
      font-size: 20px;
    }
  }
}
@media all and (max-width: 1280px){
  .tabs{
    .nav-tabs .nav-link {
      font-size: 18px;
    }
    .tab-content{
      padding-top: 60px;
    }
  }
}