.search-needs{
  .desc-block{
    &__header{
      margin-bottom: 29px;
    }
  }
}
.connect{
  margin-top: 140px;
}

.work-smarter{
  margin-top: 140px;
  padding-bottom: 140px;
  .desc-block{
    &__header{
      margin-bottom: 29px;
    }
  }
    &--2{
      margin-top: 275px;
      .desc-block__buttons {
        margin-top: 35px;
      }
    }

}

.kickstart{
  .desc-block{
    &__header{
      margin-bottom: 29px;
    }
  }
}

.today{
  margin-top: 140px;
  .desc-block{
    &__header{
      margin-bottom: 29px;
    }
    &__buttons {
      margin-top: 40px;
    }
  }
}

.faqs{
  margin-top: 25px;
  padding-bottom: 151px;
  .collapsing{
    transition:height .35s ease;
  }

  .card{
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin-bottom: 15px;
  }
  .card-header{
    padding: 0;
    margin-bottom: 0;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);

  }
  #accordion{
    margin: auto;
    width: 100%;
  }
  .mb-0{
    height: 87px;
    width: auto;
    display: flex;
  }

  .btn {
    text-align: left;
    border: none;
    padding: 0 0 0 67px;
    font-size: 28px;
    font-family: Avenir-Heavy, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #273147;
    border-radius: 0;
    width: 100%;
    position: relative;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    white-space: normal;
    text-decoration: none;
    &:after{
      content: url("../img/triangle.svg");
      border-radius: 26px;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      line-height: .65;
      width: 26px;
      height: 26px;
      position: absolute;
      top: calc(50% - 13px);
      right: 38px;
      transform: rotate(-90deg);
      transition: all .2s linear;
      font-size: 28px;
    }

  }
  .btn[aria-expanded="true"]{
    &:after{
      content: url("../img/triangle.svg");
      background-image: linear-gradient(317deg, #9ef3c1, #20a2ff 53%, #5533ff);
      transform: rotate(0deg);
    }
  }

  .btn-link:hover{
    text-decoration: none;
  }

  .card-body{
    font-size: 18px;
    line-height: 2.06;
    color: #273147;

    padding: 31px 67px;
  }
}

@media all and (max-width: 1440px){
  .faqs{
    .btn{
      font-size: 25px;
    }
    .card-body {
      font-size: 16px;
    }
  }
}

@media all and (max-width: 1280px){
  .tabs{
    margin-top: -52px;
    .nav-tabs{
      max-width: 600px;
      .nav-link{
        width: 200px;
        height: 60px;
      }
    }
  }
  .faqs{
    padding-bottom: 60px;
    #accordion{
      margin: auto;
      width: 100%;
    }
    .mb-0{
      width: auto;
      height: 110px;
    }
    .btn {
      padding: 10px 70px;
      font-size: 22px;
    }
    .card-body{
      font-size: 16px;
    }
  }
}

@media all and (max-width: 768px){
  .tabs{
    margin-top: -52px;
    .nav-tabs{
      max-width: 100%;
      justify-content: center;
    }
  }
  .search-needs, .kickstart,
  .connect, .work-smarter,
  .today, .work-smarter{
    margin-top: 60px;
    .illustration{
      width: auto;
    }
    .desc-block{
      margin: 0;
      max-width: 100%;
      &--toright{
        margin: 0;
      }
    }
  }

  .work-smarter {
    padding-bottom: 60px;
  }
  .search-needs, .kickstart{
    margin-top: 30px;
  }

}

@media all and (max-width: 630px){
  .tabs{
    margin-top: -52px;
    .nav-tabs{
      max-width: 100%;
      justify-content: center;
      flex-wrap: nowrap;
      .nav-link{
        padding: 0 10px;
        font-size: 16px;
        width: 107px;
        text-align: center;
      }
    }
  }
}

@media all and (max-width: 425px){
  .faqs{
    .btn {
      padding: 0 50px 0 20px;
      font-size: 18px;
      &:after{
        right: 10px;
      }
    }
    .card-body {
      padding: 30px 50px 30px 20px;
      font-size: 14px;
    }
  }
}