*{
  box-sizing: border-box;
}
body{
  font-family: Avenir-Medium, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #fff;

  color: #273147;

}

a, a:hover, a:active, a:focus{
  text-decoration: none;
  outline: none;
  color: #fff;
}
a {
  display: inline-block;
  cursor: pointer;
}

button{
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0;
  outline-offset: 0;
  outline: rgba(255, 255, 255, 0) solid 2px;
  transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1);
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

h4{
  font-family: Avenir-Heavy, sans-serif;
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3dcd8d;
}

p{
  margin: 0;
  padding: 0;
}
section{
  overflow: hidden;
}

.wrapper{
  display: flex;
  padding: 0 20px;
  max-width: 1140px;
  margin: auto;
  justify-content: space-between;

  &--header{
    margin: 97px 0 0 auto;
    margin-left: calc(50% - 570px);
    padding: 0 0 0 20px;
    max-width: 1814px;
    justify-content: space-between;
  }

  &--about{
    max-width: 1140px;
    justify-content: space-between;
  }

  &--faqs{
    max-width: 1140px;
    margin: 0 auto;
  }
}



.section-header{
  &--centred{
    padding-left: 40px;
  }
}
.section-sup-header{
  width: 1061px;
  font-family: Avenir-Medium,sans-serif;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #273147;

  &--centred{
    margin: 30px auto 0 auto;
  }
}


.desc-block{
  max-width: calc(50% - 10px);
  flex-shrink: 0;
  &__sup-cap{
    font-size: 22px;
  }
  &__desc-cap{
    font-family: Avenir-Heavy, sans-serif;
    font-size: 28px;
    font-weight: 900;
    margin: 20px 0;
  }
  &__desc-text{
    font-family: Avenir-Medium,sans-serif;
    font-size: 18px;
    line-height: 2.06;
  }

  &__buttons{
    margin-top: 60px;
    margin-bottom: 10px;
  }
  &__btn{
    width: 222px;
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
    color: #273147;
    &:first-child{
      margin-right: 30px;
    }
  }
}

.illustration{
  width: calc(50% - 10px);
  img{
    width: 100%;
    height: auto;
  }
}

.header-buttons{
  &__btn{
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
    &--large{
      width: 290px;
      margin-right: 34px;
    }
  }
}

.item-tags{
  &__tag{
    width: 50px;
    height: 24px;
    border-radius: 3px;
    background-color: #f6f6f6;
    font-size: 16px;
    color: #b8b8b8;
    text-align: center;
    transition: color .3s ease-in-out;
    &:hover{
      color: #273147;
    }
  }
}

.activity{
  width: 100%;
  color: #b8b8b8;
  display: flex;
  margin-top: 26px;

  font-size: 16px;
  &__views{
    display: inline-block;
    position: relative;
    padding-left: 49px;
    &:before{
      content: url("../img/oval.png");
      position: absolute;
      left: 9px;
      top: 0;
    }
  }
  &__likes{
    display: inline-block;
    position: relative;
    padding-left: 46px;
    &:before{
      content: url("../img/like-copy-4.svg");
      position: absolute;
      left: 20px;
      top: 2px;
    }
  }
  &__date{
    display: inline-block;
    margin: 0 10px 0 auto;
  }
}

.see-all{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 85px;
  &__link{
    color: #3dcd8d;
    font-size: 22px;
    margin-right: 30px;
    &:hover{
      color: #3dcd8d;
    }
  }
}

.bg-green{
  background-color: #3dcd8d;
}
.bg-grey{
  background-color: #f2f2f2;
}
.bg-none{
  background-color: transparent;
}

button {
  &.bg-green{
    &:hover {
      &:hover {
        outline: #3dcd8d solid;
        outline-offset: 5px;
      }
    }
  }

  &.bg-grey{
    &:hover {
      &:hover {
        outline: #f2f2f2 solid;
        outline-offset: 5px;
      }
    }
  }
}

.lined{
  position: relative;
  &:after{
    display: block;
    content: '';
    width: 52px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 26px);
    height: 2px;
    background-color: transparent;
    transition: all .5s ease-in-out;
  }
  &:hover{
    &:after{
      background-color: #fff;
    }
  }
  &--foot{
    &:after{
      width: 100%;
      left: 0;
      height: 1px;
      bottom: -5px;
    }
  }
  &--green{
    &:hover{
      &:after{
        background-color: #3dcd8d;
      }
    }
  }
  &--black{
    &:hover{
      &:after{
        background-color: #273147;
      }
    }
  }
}

.fc-white{
  color: #fff;
}
.fc-common{
  color: #273147;
}

.ff-light{
  font-family: Avenir-Light, sans-serif;
}
.ff-oblq{
  font-family: Avenir-Book-Oblique, sans-serif;
}

.ta-center{
  text-align: center;
}

/*Fonts*/
@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir-Roman.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Avenir-Heavy";
  src: url("../fonts/Avenir-Heavy.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Avenir-Medium";
  src: url("../fonts/Avenir-Medium.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Avenir-Light";
  src: url("../fonts/Avenir-Light.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Avenir-Black";
  src: url("../fonts/Avenir-Black.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Avenir-Book-Oblique";
  src: url("../fonts/Avenir-BookOblique.ttf") format("truetype");
  font-style: normal;
}

@media all and (min-width: 769px){
  .head-nav {
    &__links {
      display: flex !important;
    }
  }
}

@media all and (min-width: 1921px){
  .wrapper{
    margin-left: auto;
    margin-right: auto;

    &--header{
      margin-left: auto;
      margin-right: auto;
      max-width: 1140px;
    }
  }


}

@media all and (max-width: 1440px){
  body{
    font-size: 16px;
  }
  h4{
    font-size: 36px;
  }

  .section-sup-header{
    font-size: 20px;
  }
  .desc-block{
    &__sup-cap{
      font-size: 20px;
    }
    &__desc-text{
      font-size: 16px;
    }
    &__desc-cap{
      font-size: 25px;
    }
    &__btn{
      width: 180px;
      height: 50px;
      font-size: 18px;

    }
  }
}

@media all and (max-width: 1280px){
  .wrapper{
    flex-wrap: wrap;
    &--header{
      max-width: 1140px;
      margin: 97px auto 0 auto;
      flex-wrap: nowrap;
    }
  }
  .section-sup-header {
    width: 640px;

  }

  .desc-block{
    &__desc-cap{
      margin: 15px 0 15px 0;
    }
    &__buttons{
      margin-top: 15px;
    }
    &__btn{
      font-size: 18px;
      width: 140px;
      height: 50px;
      margin-right: 10px;
    }
  }
}

@media all and (max-width: 768px){
  .wrapper--header {
    flex-direction: column;
    align-items: center;
  }
  .wrapper--about {
    flex-direction: column;
    align-items: center;
  }
  .desc-block{
    order: 2;
  }
  .illustration{
    order: 1;
  }
}

@media all and (max-width: 425px){
  body{
    font-size: 14px;
  }

  h4{
    font-size: 28px;
  }

  .section-sup-header{
    font-size: 17px;
  }
  .desc-block{
    &__sup-cap{
      font-size: 17px;
    }
    &__desc-text{
      font-size: 14px;
    }
    &__desc-cap{
      font-size: 22px;
    }
    &__btn{
      font-size: 16px;

    }
  }

  .wrapper--header {
    padding: 0 10px;
  }
  .desc-block__buttons {
    display: flex;
    justify-content: space-between;
  }
  .desc-block__btn {
    height: 40px;
    margin: 0;
  }
}