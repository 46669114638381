footer{
  background-color: #273147;
  height: 489px;
  padding-top: 56px;

  .wrapper{
    justify-content: space-between;
    max-width: 1140px;
  }
}

.foot-menu{
  display: flex;
  flex-direction: column;

  &__header{
    font-family: Avenir-Heavy, sans-serif;
    color: #fff;
    font-size: 28px;
    width: 100%;
    margin: 0 0 38px 0;
  }
  &__link{
    line-height: 2.06;
    color: #ffffff;
    margin-bottom: 7px;
  }

  &__social{
    margin-top: -3px;
  }
  &__social-link{
    margin-right: 10px;
    transition: all .5s ease-in-out;
    &:hover{
      transform: rotate(360deg);
    }
  }

}


@media all and (max-width: 1280px){
  .foot-menu{
    width: 180px;
    margin: 0;
    &__header{
      font-size: 24px;
    }
    &__link{
      line-height: 1.8;
      font-size: 14px;
    }
    &__social-link{
      width: 30px;
      img{
        width: 100%;
        height: auto;
      }
    }

  }
}

@media all and (max-width: 768px){
  footer {
    height: auto;
    padding-bottom: 36px;

    .wrapper {
      flex-wrap: wrap;
      flex-direction: row;
      text-align: center;
      justify-content: center;
    }
  }

  .foot-menu {
    width: 50%;
    margin-bottom: 20px;

    &__header {
      margin-bottom: 10px;
    }

    &__link {
      margin-bottom: 0;
    }
    &__social-link{
      margin: 0;
    }
  }
}

@media all and (max-width: 425px){
  .foot-menu {
    width: 320px;
  }
}